<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {errorCatcher} from "../../../helpers/error-catcher";
import {iframeHelper} from "../../../helpers/iframe-helper";

/**
 * Instructions Component
 */
export default {
  computed: {
    iframeHelper() {
      return iframeHelper
    },
    dateUtil() {
      return dateUtil
    }
  },

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      instructions: null,
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('instructions.title'),
          active: true
        }
      ]
    },

    async loadInstructions() {
      try {
        const {data} = await axios.get(`/instruction/${this.$route.params.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.instructions = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }

  },

  async created() {
    await this.loadInstructions()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('instructions.title')"/>
    <div class="row">
      <div class="col-12">
        <div class="mb-2">
          <router-link to="/dashboard/instructions-categories" class="btn btn-primary w-sm mb-2">
            {{ $t('instructions.back-button') }}
          </router-link>
        </div>

        <template v-if="instructions">
          <template v-if="instructions.length !== 0">
            <div class="row">
              <div v-for="(instruction, index) in instructions" :key="index" class="col-12 col-lg-6">
                <div class="card">
                  <div class="card-body">
                    <h4 style="color: var(--author);">{{ instruction.title }}</h4>
                    <p style="color: var(--author);" class="mb-4">{{ $t('instructions.added-at')}} {{ dateUtil.asDateTime(instruction.createdAt) }}</p>
                    <div style="color: var(--author);" v-html="iframeHelper.convert(instruction.content)"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <h5 class="text-center py-3">{{ $t('message.no-elements') }}</h5>
          </template>
        </template>
        <template v-else>
          <div class="text-center py-3">
            <h5>{{ $t('message.loading') }}</h5>
            <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
          </div>
        </template>
      </div>
    </div>

  </Layout>
</template>